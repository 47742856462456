import moment from 'moment';
import { getIn } from "formik";
import { rsvpStatus, DOC_TYPES } from "./constants"
import appConfig from '../appConfig.json';
import _ from 'lodash';
import axios from 'axios';
import { saveDocConversionSurveyForm } from '../redux/surveyForms/surveyForms.actions';



/* 
	Validation formatted utility used for converting backend validation messages to front end recognizable format
*/

export const validationErrorFormatter = (errorData) => {

	const errorDataFormatted = {};
	for (const item in errorData)
	{
		errorDataFormatted[item] = errorData[item][0];
	}
	return { apiErrors: errorDataFormatted };
}


/* 
	Must be used in all input components where back end validation is necessary,
	this utility will control showing backend error messages 
*/
export const getError = (name, { touched, errors, status }) => {
	const fieldTouched = getIn(touched, name);
	const backendError = getIn(status, ["apiErrors", name]);
	const clientError = getIn(errors, name);
	if (clientError && fieldTouched)
	{
		return clientError;
	}
	if (backendError && !fieldTouched)
	{
		return backendError;
	}
	return undefined;
};



/* 
	Utility for generating colors by randomly picking form array, if textwhite is passed dark background will be used, else white backgroud will be used
*/
/*
export const colorGenerator = (colorFor) => {

	if (colorFor == 'textWhite')
	{
		const letters = 'BCDEF'.split('');
		let color = '#';
		for (var i = 0; i < 6; i++)
		{
			color += letters[Math.floor(Math.random() * letters.length)];
		}
		return color;
	}
	else
	{

		let letters = '012345'.split('');
		let color = '#';
		color += letters[Math.round(Math.random() * 5)];
		letters = '0123456789ABCDEF'.split('');
		for (var i = 0; i < 5; i++)
		{
			color += letters[Math.round(Math.random() * 15)];
		}
		return color;
	}
}
*/



/* 
	Utility for making number suffix eg: 1st , 2nd , 3rd
*/

export const numberSuffixMaker = (i) => {
	var j = i % 10,
		k = i % 100;
	if (j == 1 && k != 11)
	{
		return i + "st";
	}
	if (j == 2 && k != 12)
	{
		return i + "nd";
	}
	if (j == 3 && k != 13)
	{
		return i + "rd";
	}
	return i + "th";
}


/* 
	Utility to append size when bytes is given and return it
*/
export function bytesToSize(bytes, shouldRound = false, decimals = 1) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


/* 

	Utility to transform array of items to array of objects with id , second argument (searchRecipientName) is option name needed
*/
export const arrayToRandomObjectsTransformer = (data, searchRecipientName) => {

	var transformedData = [];
	var participantsList = (data.constructor.name == "Object") ? Object.values(data) : data;
	if (participantsList)
	{
		participantsList.map((item, key) => {
			const timestamp = new Date().getUTCMilliseconds();
			if (item.constructor.name == "Array")
			{
				item.map((val, key) => {
					transformedData.push({
						id: `${timestamp + key}`, searchRecipientName: val
					})
				})
			} else
			{
				transformedData.push({
					id: `${timestamp + key}`, searchRecipientName: item
				})
			}
		})
	}
	return transformedData;
}



/* 
	Utility to remove spaces and lowercase the provided string
*/
export const toLowercaseAndRemoveSpaces = (string) => {
	if(string){
		return string.replace(/ /g, '').toLowerCase();
	}
	return string
}



/* 
	Utility to search for query string and return it eg: http://localhost:3000/mail/inbox/1?action=compose
	will return 'compose'
*/
export const getUrlQueryString = (searchString, searchFor) => {
	const params = new URLSearchParams(searchString);
	const queryString = params.get(searchFor);
	return queryString;
}


/* 
	Check if object is empty
*/

export function isObjectEmpty(obj) {
	for (var key in obj)
	{
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}



/* 
	Utility to get extension with filename or url
*/

export const getFileExtension = (fileNameOrURL, showUnixDotFiles) => {
	/* First, let's declare some preliminary variables we'll need later on. */
	var fileName;
	var fileExt;

	/* Now we'll create a hidden anchor ('a') element (Note: No need to append this element to the document). */
	var hiddenLink = document.createElement('a');

	/* Just for fun, we'll add a CSS attribute of [ style.display = "none" ]. Remember: You can never be too sure! */
	hiddenLink.style.display = "none";

	/* Set the 'href' attribute of the hidden link we just created, to the 'fileNameOrURL' argument received by this function. */
	hiddenLink.setAttribute('href', fileNameOrURL);

	/* Now, let's take advantage of the browser's built-in parser, to remove elements from the original 'fileNameOrURL' argument received by this function, without actually modifying our newly created hidden 'anchor' element.*/
	fileNameOrURL = fileNameOrURL.replace(hiddenLink.protocol, ""); /* First, let's strip out the protocol, if there is one. */
	fileNameOrURL = fileNameOrURL.replace(hiddenLink.hostname, ""); /* Now, we'll strip out the host-name (i.e. domain-name) if there is one. */
	fileNameOrURL = fileNameOrURL.replace(":" + hiddenLink.port, ""); /* Now finally, we'll strip out the port number, if there is one (Kinda overkill though ;-)). */

	/* Now, we're ready to finish processing the 'fileNameOrURL' variable by removing unnecessary parts, to isolate the file name. */

	/* Operations for working with [relative, root-relative, and absolute] URL's ONLY [BEGIN] */

	/* Break the possible URL at the [ '?' ] and take first part, to shave of the entire query string ( everything after the '?'), if it exist. */
	fileNameOrURL = fileNameOrURL.split('?')[0];

	/* Sometimes URL's don't have query's, but DO have a fragment [ # ](i.e 'reference anchor'), so we should also do the same for the fragment tag [ # ]. */
	fileNameOrURL = fileNameOrURL.split('#')[0];

	/* Now that we have just the URL 'ALONE', Let's remove everything to the last slash in URL, to isolate the file name. */
	fileNameOrURL = fileNameOrURL.substr(1 + fileNameOrURL.lastIndexOf("/"));

	/* Operations for working with [relative, root-relative, and absolute] URL's ONLY [END] */

	/* Now, 'fileNameOrURL' should just be 'fileName' */
	fileName = fileNameOrURL;

	/* Now, we check if we should show UNIX dot-files, or not. This should be either 'true' or 'false'. */
	if (showUnixDotFiles == false)
	{
		/* If not ('false'), we should check if the filename starts with a period (indicating it's a UNIX dot-file). */
		if (fileName.startsWith("."))
		{
			/* If so, we return a blank string to the function caller. Our job here, is done! */
			return "";
		};
	};

	/* Now, let's get everything after the period in the filename (i.e. the correct 'file extension'). */
	fileExt = fileName.substr(1 + fileName.lastIndexOf("."));

	/* Now that we've discovered the correct file extension, let's return it to the function caller. */
	return fileExt;
};



/* 
	Utility to create blob url
	used when url is need to show preview of image files selected from computer at the time of file uploads etc..
*/

export const getObjectUrl = (file) => URL.createObjectURL(file);


/**
 * Function to get label for grouping participants based on their response
 * { 'accepted':'2',  'declined': '3', 'tentative':'4', 'not_responded':'5' }
 */
export const getLabelByKey = (key) => {

	let optionValue = rsvpStatus.find((item) => {
		if (item.id == key)
		{
			return item;
		}
	})
	if (optionValue)
	{
		return optionValue.label
	} else
	{
		return 'Not Responded';
	}
}



export const checkResponded = (response_status) => {
	var responseArr = ["2", "3", "4"]; //{ 'accepted':'2',  'declined': '3', 'tentative':'4', 'not_responded':'5' }
	if (response_status && responseArr.includes(response_status))
	{
		return true;
	}
	return false;
}

/**
 * Function to get event timings for event view modal
 */
export const getEventTiming = (eventData) => {

	var fromDate = new Date(eventData.event_start);
	var toDate = new Date(eventData.event_end);
	var all_day = eventData.is_all_day;
	var fromDateFormatted = fromDate.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })
	var toDateFormatted = toDate.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })



	const eventStart = new Date(eventData.event_start).toLocaleDateString('en-GB')
	const eventEnd = new Date(eventData.event_end).toLocaleDateString('en-GB')

	if (eventStart == eventEnd)
	{
		if (all_day)
		{

			return fromDateFormatted;
		}
		else
		{
			return fromDateFormatted + ' . ' + moment(eventData.event_start).format('hh:mm a') + ' to ' + moment(eventData.event_end).format('hh:mm a');
		}
	}
	else
	{
		if (all_day)
		{
			/* 
				if all day , 1 day is added at the time of creation of event, adjusting it by reducing one day
			*/
			return fromDateFormatted + ' - ' + moment(toDateFormatted).subtract(1, 'days').format('D MMM YYYY');
		}
		else
		{
			return fromDateFormatted + ' . ' + moment(eventData.event_start).format('hh:mm a') + ' to ' + toDateFormatted + ' . ' + moment(eventData.event_end).format('hh:mm a');
		}
	}
}


/* 
	Utility to get file name only from name with extension
*/


export const removeExtensionFromFileName = (filename) => filename.split('.').slice(0, -1).join('.');




/* 
	Utility function to make array of objects with unique id. index name of object will be the passed name (objectIndexName)
*/

export const uniqueIdObjectMaker = (arrayData, objectIndexName) => {
	var timestamp = new Date().getUTCMilliseconds();
	return arrayData.map((item, i) => {
		return { id: `${timestamp + i}`, [objectIndexName]: item }
	})
}


/* 
	Utility function to get trimmed string with dots at the end
*/



export const getTrimmedString = (string, length, appendDots = true) => {
	if (string && string.length >= length)
	{
		if (appendDots)
		{
			return string.substring(0, length) + '...';
		}
		else
		{
			return string.substring(0, length);
		}
	}
	return string;
}
export const getShortForm = (string) => {
	if (string)
	{
		// var matches = string.match(/\b(\w)/g);
		// var short = matches.join('');
		//return short.toUpperCase();
		var initials = string.match(/\b\w/g) || [];
		initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
		return initials;
	}
	return false;
}






export const getFileNameFromLink = (fileUrl) => {
	if (fileUrl)
	{
		return fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
	}
}




/* 
	Utility to check if total attachment size has crossed acceptable size
*/
export const hasTotalAttachmentsSizeCrossed = (maxFileAttachmentSize, totalAttachmentFileSize) => {
	if (totalAttachmentFileSize > maxFileAttachmentSize)
	{
		return true;
	}
	else
	{
		return false;
	}
}


/* 
	Utility to split string by space and get first 2 letter
	eg: 'test man' --> tm
*/
export const splitBySpaceAndGetFirstTwoLetters = (name) => {
	const splittedArray = name.split(" ");
	const firstLetterOfFirstName = splittedArray[0].substring(0, 1);
	let firstLetterOfSecondName;
	if (splittedArray.length > 1)
	{
		firstLetterOfSecondName = splittedArray[1].substring(0, 1);
		return `${firstLetterOfFirstName}${firstLetterOfSecondName}`;
	}
	else
	{
		return firstLetterOfFirstName
	}
}



/* 
	Utility function for taking src url from file prototype object 
*/

export const getFileSrcUrlFromFileObject = (filePrototype) => {
	return URL.createObjectURL(filePrototype)
}


/* 
	Utility function to take parameters from url
*/
export function getUrlParameters() {
	let url = window.location.search;

	url = url.substr(1, url.length);
	let queryParamAry = url.split('&');
	let campaignData;
	let queryParam = {};
	for (let count = 0, length = queryParamAry.length; count < length; count++)
	{
		campaignData = queryParamAry[count].split('=');
		queryParam[campaignData[0]] = campaignData[1];
	}
	return queryParam;
}




/* 
	Utility function to remove outermost tag
*/
export const removeOuterMostTag = (text, tag) => {
	const regex = new RegExp(`^<${tag}>|</${tag}>$`, 'g');
	return text.replace(regex, "").trim();
}



/* 
	Utility to remove spaces and substitute it with character passed
*/
export const toLowercaseAndReplaceSpaces = (string, replaceSpaceWith = '') => string.replace(/ /g, replaceSpaceWith).toLowerCase();











export const findAge = (dob) => {
	if (dob) {
		// Check if the DOB is not more than 100 years before the current year
		var today = moment();
		var birth = moment(dob, 'YYYY-MM-DD');
		var maxDOB = moment(today).subtract(100, 'years');
		if (birth.isBefore(maxDOB)) {
			return "DOB must be within the last 100 years.";
		}

		var ageString = "";
		var age = moment.duration(today.diff(birth));
		var years = age.years();
		var months = age.months();
		var days = age.days();
		if (years) {
			ageString = ageString + years + " " + (years > 1 ? "Years" : "Year") + " ";
		}
		if (months) {
			ageString = ageString + months + " " + (months > 1 ? "Months" : "Month");
		}
		if (!years && !months && days) {
			ageString = days + " " + (days > 1 ? "Days" : "Day");
		}
		return ageString;
	}
	return " ";
};
export const getFormattedOptions = (data, fieldName) => {
	let tempArray = [];
	let tempObject = {};
	data.forEach((item) => {
		if (item.name)
		{
			tempArray.push({ id: item.name, option: item.name })
		} else if (item.first_name)
		{
			tempArray.push({ id: item.first_name + ' ' + item?.last_name, option: item.first_name + ' ' + item?.last_name })
		}
	})
	tempObject[fieldName] = tempArray;
	return tempObject;
}

export const getFormattedSurvey = (data) => {
	if (data)
	{
		data.forEach((category) => {
			category.data && (category.data.length > 0) && category.data.forEach((subcategory) => {

				(subcategory.subcategory_data.length > 0) && subcategory.subcategory_data.forEach((questions) => {

					questions.has_sublabels && (questions.sub_labels.length > 0) && questions.sub_labels.forEach((subquestions) => {
						subquestions.sub_label_data && (subquestions.sub_label_data.length > 0) && subquestions.sub_label_data.forEach((subquestionItem) => {

							subquestionItem.label_parent = (!subquestionItem.label_parent) ? questions.field_name : subquestionItem.label_parent;

							if (!subquestionItem.response_required)
							{
								subquestionItem.has_sublabels && (subquestionItem.sub_labels.length > 0) && subquestionItem.sub_labels.forEach((subSubquestions) => {

									subSubquestions.sub_label_data && (subSubquestions.sub_label_data.length > 0) && subSubquestions.sub_label_data.forEach((subSubquestionItem) => {

										subSubquestionItem.label_parent = (!subSubquestionItem.label_parent) ? questions.field_name : subSubquestionItem.label_parent;
									})
								})
							}
						})
					})
				})
			})
		})
		return data;
	}
	return false;
}

export const validateAge = (dob) => {

	var today = moment();
	var birth = moment(dob, 'YYYY-MM-DD');
	var age = moment.duration(today.diff(birth));
	var years = age.years();
	if (years >= appConfig.legal_age)
	{
		return true;
	}
	return false;
}

// FUnction to convert json string into array
export const convertInitialValuesForInput = (str) => {
	if (typeof str === "string")
	{
		var x = str.replace(/\'/g, '"');
		let newStr;
		try
		{
			newStr = JSON.parse(x);
		}
		catch (e)
		{
			newStr = str;
		}
		return newStr;
	}
	return str;
}

export const stringifyPathForInitialvalues = (path) => {
	var newPath = path.replace(/[^A-Za-z0-9]/ig, "_");
	return newPath + "_check"
}





/* 
	Utility function to check for permissions 
*/

export const checkPermissions = (
	availablePermissions = [],
	allowedPermissions = [],
	type = '',
	allowedRoles = []
) => {
	let hasPermission = false;

	if (allowedPermissions.length > 0)
	{
		allowedPermissions.forEach(allowedPermissionItem => {
			if (availablePermissions.includes(allowedPermissionItem))
			{
				hasPermission = true;
			}
		});
	}

	if (allowedRoles.length > 0)
	{
		if (allowedRoles.includes(type))
		{
			hasPermission = true;
		}
	}
	return hasPermission;
};



/* 
  Utility function to check if date is valid 
*/
export const checkDateIsValid = dateObject => new Date(dateObject).toString() !== 'Invalid Date';




/* 
	Utility function to convert string to sentence case.
*/

function stringSentenceCase(str) {
	return str.charAt(0).toUpperCase() + str.slice(1).replace(/\.\s*([a-z])/uig, (_, letter) => `. ${letter.toUpperCase()}`)
}


/* 
	Utility function to capitalize first letter 
*/
function firstLetterOfEachWordToUppercase(str) {
	let upper = true;
	let newStr = "";
	for (let i = 0, l = str.length; i < l; i++)
	{
		if (str[i] == " ")
		{
			upper = true;
			newStr += " ";
			continue;
		}
		newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase();
		upper = false;
	}
	return newStr;
}

/* 
	Utility function to determine what case to convert to
*/
export const stringCaseSwitcher = (caseToCovert, value) => {
	if (value && isNaN(value))
	{
		switch (caseToCovert)
		{
			case 'titleCase':
				return firstLetterOfEachWordToUppercase(value)
			case 'sentenceCase':
				return stringSentenceCase(value)
			case 'lowercase':
				return value.toLowerCase();
			case 'upperCase':
				return value.toUpperCase();
			case '':
				return value;
			default:
				return value;
		}
	}
	return value;
}

export const getInputFieldCase = (fieldItem) => {

	let inputCase = 'titleCase';
	if (fieldItem.component_type === 'Input')
	{
		if (fieldItem.value_type === 'Email')
		{
			inputCase = 'lowercase';
		}
	}
	else if (fieldItem.component_type === 'TextArea')
	{
		inputCase = 'sentenceCase';
	}
	// Custom logic for case conversion, from appConfig
	if (appConfig.survey_forms.upperCaseFields.includes(fieldItem.label_slug))
	{
		inputCase = 'upperCase';
	}
	if (appConfig.survey_forms.lowerCaseFields.includes(fieldItem.label_slug))
	{
		inputCase = 'lowercase';
	}
	if (appConfig.survey_forms.sentenceCaseFields.includes(fieldItem.label_slug))
	{
		inputCase = 'sentenceCase';
	}
	return inputCase;
}



/* 
	Utility function to convert a blob to file object
*/

export const convertBlobToFile  = (blob, fileName) => {
	return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type, dude : true })
}

export const getFormattedValueFromExtraction = (extractedItem) => {
    let formatted = extractedItem.extracted_value;
    // if(extractedItem.extracted_value){
    //     if(extractedItem.value_type === 'Amount'){
	// 		// formatted = <NumberFormat value={extractedItem.extracted_value} displayType={'text'} thousandSeparator={true} prefix={appConfig.currency.currency_symbol} />
	// 		formatted = extractedItem.extracted_value;
    //     }else if(extractedItem.value_type === 'Date'){
	// 		formatted = extractedItem.extracted_value;
	// 		// Commented so that the date formatting will be handled in back-end
    //         // var momentObj = moment(extractedItem.extracted_value, 'DD/MM/YYYY');
    //         // formatted = momentObj.format('D MMM YYYY');
    //     }else if(extractedItem.value_type === 'Percentage'){
    //         formatted = (extractedItem.extracted_value.includes("%") === false) ? extractedItem.extracted_value + '%' : extractedItem.extracted_value;
    //     }

    // }
    return formatted;
}

export const downloadFileFromUrl = async (fileUrl, fileNameString=null, isExactFileName=false) => {

	let fileName;
	if(fileNameString){
		if(isExactFileName){
			fileName = fileNameString;
		}else{
			fileName = getFileNameFromLink(fileNameString);
		}
	}else{
		fileName = getFileNameFromLink(fileUrl);
	}
	let blob = await fetch(fileUrl).then(r => r.blob());
	if(blob){
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
	}
}

export const formatDataForMultiselect = (data, list='') => {
	let returnData = [];
	data && data.map((item)=>{
		if(!isNaN(item)){
			var listObj = list.filter((element) => {
				return element.id === item
			  });
			  if(listObj[0]){
				returnData.push(listObj[0])
			  }
		}else{
			returnData.push(item.id)
		}
	})
	return returnData;
}

/**
 * FUnction for formatting staff list used in task comment section
 */
export const formatStaffForMention = (staffList, excludeIdArray=[]) => {
	let formattedList = [];
	staffList && staffList.map((item)=>{
		if(item.user && !excludeIdArray.includes(item.id)){
			formattedList.push({"id":item.id, "display":item.user.first_name+' '+item.user.last_name})
		}
	})
	return formattedList;
}

/**
 * Function for formatting document name
 */
export const formatDocName = (documentName, firstCharsLength=3, lastCharsLength=3, extensionRequired) => {

	if(documentName){
		let firstPart, lastPart;
		let filename = documentName.substring(0, documentName.lastIndexOf('.')) || documentName;
		if(filename && filename.length > (firstCharsLength + lastCharsLength)){
			if(firstCharsLength){
				firstPart = filename.substring(0, firstCharsLength);
			}
			if(lastCharsLength){
				lastPart = filename.substring(filename.length-lastCharsLength);
			}
			if(extensionRequired){
				let extension = documentName.split('.').pop();
				return `${firstPart}...${lastPart}.${extension}`;
			}
			return `${firstPart}...${lastPart}`;
		}
		return documentName;
	}
}

/**
 * Get formatted date from iso date string with zone
 */
export const formatIsoDateString = (dateString) => {

	let dateObj = new Date(dateString)
	if(dateObj.toString() !== 'Invalid Date'){
		let monthNames =["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep", "Oct","Nov","Dec"];
		let day = dateObj.getDate();
		let monthIndex = dateObj.getMonth();
		let monthName = monthNames[monthIndex];
		let year = dateObj.getFullYear();
		return `${day} ${monthName} ${year}`;
	}
}

export const getTimingsForNextMeeting = (start, end, is_allday) => {

	var fromDate = new Date(start);
	var toDate = new Date(end);
	var all_day = is_allday;
	var fromDateFormatted = fromDate.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric' })
	var toDateFormatted = toDate.toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric' })



	const eventStart = new Date(start).toLocaleDateString('en-GB')
	const eventEnd = new Date(end).toLocaleDateString('en-GB')

	if (eventStart == eventEnd)
	{
		if (all_day)
		{

			return fromDateFormatted;
		}
		else
		{
			return fromDateFormatted + ' . ' + moment(start).format('hh:mm a') + ' to ' + moment(end).format('hh:mm a');
		}
	}
	else
	{
		if (all_day)
		{
			/* 
				if all day , 1 day is added at the time of creation of event, adjusting it by reducing one day
			*/
			return fromDateFormatted + ' - ' + moment(toDateFormatted).subtract(1, 'days').format('D MMM YYYY');
		}
		else
		{
			return fromDateFormatted + ' . ' + moment(start).format('hh:mm a') + ' to ' + toDateFormatted + ' . ' + moment(end).format('hh:mm a');
		}
	}
}

export const combineResult = (currentData, responsePayload, currentDataFolder) => {

	const {data, count, offset, folder} = responsePayload;
	const folderId = folder ? parseInt(folder) : null;

	if(data && data.length > 0){
		if((currentData && currentData.length > 0) && currentDataFolder === folderId){
			var newlyFetchedMail2 = _.differenceBy(currentData, data, 'id');
			return [...newlyFetchedMail2, ...data]
		}else{
			return [...data]
		}
	}else{
		if(currentData && currentDataFolder === folderId){
			return [...currentData]
		}else{
			return []
		}
	}
}

export const fetchGet = async (userToken, fetchurl) => {
    return await fetch(
      `${process.env.REACT_APP_BASE_URL}${fetchurl}`,
  
      {
        method: "GET",
        headers: {
          Authtoken: 'Token ' + `${userToken}`,
          type: 'application/json'
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data);
  }

  export const mapAdvisor = async (userToken, fetchurl,clientId,formData) => {
	return await fetch(
	  `${process.env.REACT_APP_BASE_URL}${fetchurl}/${clientId}`,
  
	  {
		method: "PUT",
		body:   formData,
		headers: {
		  Authtoken: `Token ${userToken}`,
		
		},
	  }
	)
	  .then((res) => res.json())
	  .then((data) => data);
  }
export const removeDeletedMail = (mailList, deletedMailId) => {

	if(deletedMailId){
		if(mailList && mailList.length > 0){
			var tmpArray = mailList;
			_.remove(tmpArray, item => item.id === deletedMailId);
			return [...tmpArray]
		}
	}else{
		return mailList;
	}
}

export const markReadMail = (mailList, readMailId) => {

	if(readMailId){
		if(mailList && mailList.length > 0){
			mailList.forEach((item)=>{
				if(item.id === readMailId){
					item.message_is_read = true
				}
			})
			return mailList;
		}
	}else{
		return mailList;
	}
}


/* 
	Utility function for abbreviating  number with K, M etc.
*/
export const abbreviateNumber = (number, decimalsNeeded = 1, SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"],) => {
	const sign = number < 0 ? '-1' : '';
	const absNumber = Math.abs(number);
	const tier = Math.log10(absNumber) / 3 | 0;
	// if zero, we don't need a prefix
	if(tier == 0) return `${absNumber}`;
	// get postfix and determine scale
	const postfix = SI_POSTFIXES[tier];
	const scale = Math.pow(10, tier * 3);
	// scale the number
	const scaled = absNumber / scale;
	const floored = Math.floor(scaled * 10) / 10;
	// format number and add postfix as suffix
	let str = floored.toFixed(decimalsNeeded);
	// remove '.0' case
	str = (/\.0$/.test(str)) ? str.substr(0, str.length - 2) : str;
	return `${sign}${str}${postfix}`;
}



/* 
	Utility function for comma seperation of amount fields
*/

export const getCommaSeparatedNumbers = (number, showDecimalsIfZero = true, fractionDigits = 2, thousandSeperator = ',', fractionSeperator = '.') => {
	if (number!==0 && !number || !Number.isFinite(number)) return number
	const frDigits = Number.isFinite(fractionDigits)? Math.min(Math.max(fractionDigits, 0), 7) : 0
	const num = number.toFixed(frDigits).toString()

	const parts = num.split('.')
	let digits = parts[0].split('').reverse()
	let sign = ''
	if (num < 0) {sign = digits.pop()}
	let final = []
	let pos = 0

	while (digits.length > 1) {
			final.push(digits.shift())
			pos++
			if (pos % 3 === 0) {final.push(thousandSeperator)}
	}
	final.push(digits.shift())

	// if result has zero as as decimal point remove it if showDecimalsIfZero flag is false ( eg : 1,025.00 -> 1,025)
	const numberDecimalValue = parts[1];
	const numberOfZeros = new Array(fractionDigits).fill(fractionDigits).map(item => 0).join(''); // if decimals = 2, this outputs 00, if 3 , 000
	const shouldHideDecimals = (numberDecimalValue == numberOfZeros && showDecimalsIfZero === false);

	return `${sign}${final.reverse().join('')}${(frDigits > 0 && !shouldHideDecimals) ? fractionSeperator : ''}${(frDigits > 0 && parts[1] && !shouldHideDecimals)? parts[1] : ''}`
}


/**
 * Utility functions for survey forms
 * These functions were moved from survey form component for better maintainability
 */

// Funtion to create initial form data
export const getFormInitialValues = (initData,partnerInitData,joint_client,parallelly_added,dispatch,previousDocConversionData) => {
	let initialValuesArray = {};
	let initialValuesArray1 = {};
	let initialValuesArray2 = {};
	let docConversionData={};
	let checkComponentTypes = ['Text&Checkbox', 'Select&Checkbox', 'Searchable&Checkbox', 'MultiSelect&Checkbox', 'currencyField&Checkbox'];
	if(initData&&(!joint_client&&!parallelly_added)){
		initData.map((category) => {

			if(category&&category.data&&category.data.length > 0){
				category.data.map((subcategory) => {
                    let category_name=JSON.parse(category.category).category_name
					let tempArray = [];
					let tempArray2 = [];
if(!docConversionData[category_name]){
							docConversionData[category_name]=[] 
						}
					if(subcategory && subcategory.subcategory_data && subcategory.subcategory_data.length > 0){
						
						subcategory.subcategory_data.map((questions, index) => {

							initialValuesArray[questions.field_name] = convertInitialValuesForInput(questions.answer);
										docConversionData[category_name].push({label:questions.label,answer_parent:questions.answer_parent,component_type:questions.component_type,category:subcategory.subcategory_name,answer:questions.answer,subLabel:questions.label_parent?true:false,fieldName:questions.field_name})
							/* For checkbox */
							if(checkComponentTypes.includes(questions.component_type)){
								if(questions.answer != "" && (questions.answer == 0 || questions.answer === 'Unknown')){
									initialValuesArray[stringifyPathForInitialvalues(questions.field_name)] = true;
								}else{
									initialValuesArray[stringifyPathForInitialvalues(questions.field_name)] = false;
								}
							}
							/* For checkbox - End */

							if(questions.is_repeat){

								if(questions&&questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions, subIndex) => {
										tempArray.push(subQuestions);
										
										/* For checkbox */
										(subQuestions&&subQuestions.sub_label_data&&subQuestions.sub_label_data.length > 0) && subQuestions.sub_label_data.forEach((sublabelData, sublabelDataIndex)=>{
											docConversionData[category_name].push({label:sublabelData.label,answer_parent:sublabelData.answer_parent,component_type:sublabelData.component_type,category:subcategory.subcategory_name,answer:sublabelData.answer,subLabel:sublabelData.label_parent,fieldName:sublabelData.field_name,sublabelData:sublabelData?.sub_labels?.length > 0?sublabelData.sub_labels:[]})
											// if(category_name=="Net Worth Summary"&&sublabelData.field_name==
											// 	"assets_are_you_willing_to_look_at_including_any_of_the_above_in_your_financial_planning__78"){
											// 		sublabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
											// 			(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
                                            //                 docConversionData[category_name].push({label:subSublabelData.label,field:4,answer_parent:subSublabelData.answer_parent,component_type:subSublabelData.component_type,category:subcategory.subcategory_name,answer:subSublabelData.answer,subLabel:subSublabelData.label_parent||subSublabelData.answer_parent,fieldName:subSublabelData.field_name})   
											// 			})})
											// }
											var repeatFieldName = `${questions.field_name}[${subIndex}].sub_label_data[${sublabelDataIndex}].answer`;
											if(checkComponentTypes.includes(sublabelData.component_type)){
												if(sublabelData.answer != "" && (sublabelData.answer == 0 || sublabelData.answer === 'Unknown')){
													initialValuesArray[stringifyPathForInitialvalues(repeatFieldName)] = true;
												}else{
													initialValuesArray[stringifyPathForInitialvalues(repeatFieldName)] = false;
												}
											}
										})
										/* For checkbox - End */
									})
									initialValuesArray[questions.field_name] = tempArray;

								}
							}else{
								if(questions&&questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions) => {
										
										if(subQuestions&&subQuestions.sub_label_data && subQuestions.sub_label_data.length > 0){
											subQuestions.sub_label_data.map((subLabelData) => {
                                           
												if(subLabelData.is_repeat){
													if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															tempArray2.push(subSubLabels);
															/* For checkbox */
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																var repeatFieldName = `${subLabelData.field_name}[${subLabelIndex}].sub_label_data[${subSublabelDataIndex}].answer`;
																docConversionData[category_name].push({label:subSublabelData.label,answer_parent:subSublabelData.answer_parent,component_type:subSublabelData.component_type,category:subcategory.subcategory_name,answer:subSublabelData.answer,subLabel:subSublabelData.label_parent,fieldName:subSublabelData.field_name})
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray[stringifyPathForInitialvalues(repeatFieldName)] = true;
																	}else{
																		initialValuesArray[stringifyPathForInitialvalues(repeatFieldName)] = false;
																	}
																}
															})
															/* For checkbox - End */
														})
													}
													initialValuesArray[subLabelData.field_name] = tempArray2;
												}else{
													docConversionData[category_name].push({label:subLabelData.label,answer_parent:subLabelData.answer_parent,component_type:subLabelData.component_type,category:subcategory.subcategory_name,answer:subLabelData.answer,subLabel:subLabelData.label_parent,fieldName:subLabelData.field_name,sublabelData:subLabelData?.sub_labels?.length > 0?subLabelData.sub_labels:[]})
													// if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
													// subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
													// 	(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
                                                    //         docConversionData[category_name].push({label:subSublabelData.label,field:4,answer_parent:subSublabelData.answer_parent,component_type:subSublabelData.component_type,category:subcategory.subcategory_name,answer:subSublabelData.answer,subLabel:subSublabelData.label_parent,fieldName:subSublabelData.field_name})   
													// 	})})}
													initialValuesArray[subLabelData.field_name] = convertInitialValuesForInput(subLabelData.answer);
													/* For checkbox */
													if(checkComponentTypes.includes(subLabelData.component_type)){
														if(subLabelData.answer != "" && (subLabelData.answer == 0 || subLabelData.answer === 'Unknown')){
															initialValuesArray[stringifyPathForInitialvalues(subLabelData.field_name)] = true;
														}else{
															initialValuesArray[stringifyPathForInitialvalues(subLabelData.field_name)] = false;
														}
													}
													/* For checkbox - End */
												}


												// New code - Start
												if(subLabelData.has_sublabels){

													if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																initialValuesArray[subSublabelData.field_name] = convertInitialValuesForInput(subSublabelData.answer);
																/* For checkbox */
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray[stringifyPathForInitialvalues(subSublabelData.field_name)] = true;
																	}else{
																		initialValuesArray[stringifyPathForInitialvalues(subSublabelData.field_name)] = false;
																	}
																}
																/* For checkbox - End */
															})
														})
													}
												}
												// New code - End

											})
										}
									})
								}
							}
						})
					}
				})
			}
		})
	}
	if(initData&&partnerInitData&&(joint_client||parallelly_added)){
		initData.map((category) => {

			if(category&&category.data&&category.data.length > 0){
				category.data.map((subcategory) => {
                    let category_name=JSON.parse(category.category).category_name
					let tempArray = [];
					let tempArray2 = [];
					if(!docConversionData[category_name]){
						docConversionData[category_name]=[] 
					}
					if(subcategory&&subcategory.subcategory_data && subcategory.subcategory_data.length > 0){
						subcategory.subcategory_data.map((questions, index) => {

							initialValuesArray1[questions.field_name] = convertInitialValuesForInput(questions.answer);
							/* For checkbox */
							docConversionData[category_name].push({label:questions.label,answer_parent:questions.answer_parent,category:subcategory.subcategory_name,component_type:questions.component_type,answer:questions.answer,subLabel:questions.label_parent,fieldName:questions.field_name})
							if(checkComponentTypes.includes(questions.component_type)){
								if(questions.answer != "" && (questions.answer == 0 || questions.answer === 'Unknown')){
									initialValuesArray1[stringifyPathForInitialvalues(questions.field_name)] = true;
								}else{
									initialValuesArray1[stringifyPathForInitialvalues(questions.field_name)] = false;
								}
							}
							/* For checkbox - End */

							if(questions.is_repeat){

								if(questions&&questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions, subIndex) => {
										tempArray.push(subQuestions);
										/* For checkbox */
										(subQuestions&&subQuestions.sub_label_data&&subQuestions.sub_label_data.length > 0) && subQuestions.sub_label_data.forEach((sublabelData, sublabelDataIndex)=>{
											docConversionData[category_name].push({label:sublabelData.label,answer_parent:sublabelData.answer_parent,component_type:sublabelData.component_type,category:subcategory.subcategory_name,answer:sublabelData.answer,subLabel:sublabelData.label_parent,fieldName:sublabelData.field_name,sublabelData:sublabelData?.sub_labels?.length > 0?sublabelData.sub_labels:[]})
											var repeatFieldName = `${questions.field_name}[${subIndex}].sub_label_data[${sublabelDataIndex}].answer`;
											if(checkComponentTypes.includes(sublabelData.component_type)){
												if(sublabelData.answer != "" && (sublabelData.answer == 0 || sublabelData.answer === 'Unknown')){
													initialValuesArray1[stringifyPathForInitialvalues(repeatFieldName)] = true;
												}else{
													initialValuesArray1[stringifyPathForInitialvalues(repeatFieldName)] = false;
												}
											}
										})
										/* For checkbox - End */
									})
									initialValuesArray1[questions.field_name] = tempArray;

								}
							}else{
								if(questions && questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions) => {

										if(subQuestions && subQuestions.sub_label_data && subQuestions.sub_label_data.length > 0){
											subQuestions.sub_label_data.map((subLabelData) => {

												if(subLabelData.is_repeat){
													if(subLabelData && subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															tempArray2.push(subSubLabels);
															/* For checkbox */
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																docConversionData[category_name].push({label:subSublabelData.label,answer_parent:subSublabelData.answer_parent,component_type:subSublabelData.component_type,category:subcategory.subcategory_name,answer:subSublabelData.answer,subLabel:subSublabelData.label_parent,fieldName:subSublabelData.field_name})
																var repeatFieldName = `${subLabelData.field_name}[${subLabelIndex}].sub_label_data[${subSublabelDataIndex}].answer`;
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray1[stringifyPathForInitialvalues(repeatFieldName)] = true;
																	}else{
																		initialValuesArray1[stringifyPathForInitialvalues(repeatFieldName)] = false;
																	}
																}
															})
															/* For checkbox - End */
														})
													}
													initialValuesArray1[subLabelData.field_name] = tempArray2;
												}else{
													initialValuesArray1[subLabelData.field_name] = convertInitialValuesForInput(subLabelData.answer);
													/* For checkbox */
													docConversionData[category_name].push({label:subLabelData.label,answer_parent:subLabelData.answer_parent,component_type:subLabelData.component_type,category:subcategory.subcategory_name,answer:subLabelData.answer,subLabel:subLabelData.label_parent,fieldName:subLabelData.field_name,sublabelData:subLabelData?.sub_labels?.length > 0?subLabelData.sub_labels:[]})
													if(checkComponentTypes.includes(subLabelData.component_type)){
														if(subLabelData.answer != "" && (subLabelData.answer == 0 || subLabelData.answer === 'Unknown')){
															initialValuesArray1[stringifyPathForInitialvalues(subLabelData.field_name)] = true;
														}else{
															initialValuesArray1[stringifyPathForInitialvalues(subLabelData.field_name)] = false;
														}
													}
													/* For checkbox - End */
												}


												// New code - Start
												if(subLabelData.has_sublabels){

													if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																initialValuesArray1[subSublabelData.field_name] = convertInitialValuesForInput(subSublabelData.answer);
																/* For checkbox */
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray1[stringifyPathForInitialvalues(subSublabelData.field_name)] = true;
																	}else{
																		initialValuesArray1[stringifyPathForInitialvalues(subSublabelData.field_name)] = false;
																	}
																}
																/* For checkbox - End */
															})
														})
													}
												}
												// New code - End

											})
										}
									})
								}
							}
						})
					}
				})
			}
		})
		partnerInitData.map((category) => {

			if(category.data.length > 0){
				category.data.map((subcategory) => {
                    let category_name=JSON.parse(category.category).category_name
					let tempArray = [];
					let tempArray2 = [];
                    if(!docConversionData[category_name]){
						docConversionData[category_name]=[] 
					}
					if(subcategory&&subcategory.subcategory_data && subcategory.subcategory_data.length > 0){
						subcategory.subcategory_data.map((questions, index) => {
                                    let partnerFeildName ="partner_"+questions.field_name
							initialValuesArray2[partnerFeildName] = convertInitialValuesForInput(questions.answer);
							/* For checkbox */
							if(category_name!="Net Worth Summary"){
							docConversionData[category_name].push({label:questions.label,answer_parent:questions.answer_parent,component_type:questions.component_type,category:subcategory.subcategory_name,answer:questions.answer,subLabel:questions.label_parent,fieldName:partnerFeildName})
						}
							if(checkComponentTypes.includes(questions.component_type)){
								if(questions.answer != "" && (questions.answer == 0 || questions.answer === 'Unknown')){
									initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = true;
								}else{
									initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = false;
								}
							}
							/* For checkbox - End */

							if(questions.is_repeat){

								if(questions && questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions, subIndex) => {
										tempArray.push(subQuestions);
										/* For checkbox */
										(subQuestions&&subQuestions.sub_label_data&&subQuestions.sub_label_data.length > 0) && subQuestions.sub_label_data.forEach((sublabelData, sublabelDataIndex)=>{
											if(category_name!="Net Worth Summary"){
											docConversionData[category_name].push({label:sublabelData.label,answer_parent:sublabelData.answer_parent,component_type:sublabelData.component_type,category:subcategory.subcategory_name,answer:sublabelData.answer,subLabel:sublabelData.label_parent,fieldName:partnerFeildName,sublabelData:sublabelData?.sub_labels?.length > 0?sublabelData.sub_labels:[]})
											}
											var repeatFieldName = `${partnerFeildName}[${subIndex}].sub_label_data[${sublabelDataIndex}].answer`;
											if(checkComponentTypes.includes(sublabelData.component_type)){
												if(sublabelData.answer != "" && (sublabelData.answer == 0 || sublabelData.answer === 'Unknown')){
													initialValuesArray2[stringifyPathForInitialvalues(repeatFieldName)] = true;
												}else{
													initialValuesArray2[stringifyPathForInitialvalues(repeatFieldName)] = false;
												}
											}
										})
										/* For checkbox - End */
									})
									initialValuesArray2[partnerFeildName] = tempArray;

								}
							}else{
								if(questions&&questions.sub_labels && questions.sub_labels.length > 0){
									questions.sub_labels.map((subQuestions) => {
                                        
										if(subQuestions&&subQuestions.sub_label_data && subQuestions.sub_label_data.length > 0){
											subQuestions.sub_label_data.map((subLabelData) => {
												let partnerFeildName="partner_"+subLabelData.field_name
												if(subLabelData.is_repeat){
													if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															tempArray2.push(subSubLabels);
															/* For checkbox */
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																if(category_name!="Net Worth Summary"){
																docConversionData[category_name].push({label:subSublabelData.label,answer_parent:subSublabelData.answer_parent,component_type:subSublabelData.component_type,category:subcategory.subcategory_name,answer:subSublabelData.answer,subLabel:subSublabelData.label_parent,fieldName:partnerFeildName})
																}
																var repeatFieldName = `${partnerFeildName}[${subLabelIndex}].sub_label_data[${subSublabelDataIndex}].answer`;
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray2[stringifyPathForInitialvalues(repeatFieldName)] = true;
																	}else{
																		initialValuesArray2[stringifyPathForInitialvalues(repeatFieldName)] = false;
																	}
																}
															})
															/* For checkbox - End */
														})
													}
													initialValuesArray2[partnerFeildName] = tempArray2;
												}else{
													initialValuesArray2[partnerFeildName] = convertInitialValuesForInput(subLabelData.answer);
													/* For checkbox */
													if(category_name!="Net Worth Summary"){
													docConversionData[category_name].push({label:subLabelData.label,answer_parent:subLabelData.answer_parent,component_type:subLabelData.component_type,category:subcategory.subcategory_name,answer:subLabelData.answer,subLabel:subLabelData.label_parent,fieldName:partnerFeildName,sublabelData:subLabelData?.sub_labels?.length > 0?subLabelData.sub_labels:[]})
													}
													if(checkComponentTypes.includes(subLabelData.component_type)){
														if(subLabelData.answer != "" && (subLabelData.answer == 0 || subLabelData.answer === 'Unknown')){
															initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = true;
														}else{
															initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = false;
														}
													}
													/* For checkbox - End */
												}


												// New code - Start
												if(subLabelData.has_sublabels){

													if(subLabelData&&subLabelData.sub_labels && subLabelData.sub_labels.length > 0){
														subLabelData.sub_labels.map((subSubLabels, subLabelIndex) => {
															(subSubLabels&&subSubLabels.sub_label_data&&subSubLabels.sub_label_data.length > 0) && subSubLabels.sub_label_data.forEach((subSublabelData, subSublabelDataIndex)=>{
																let partnerFeildName ="partner_"+subSublabelData.field_name
																initialValuesArray2[partnerFeildName] = convertInitialValuesForInput(subSublabelData.answer);
																/* For checkbox */
																if(checkComponentTypes.includes(subSublabelData.component_type)){
																	if(subSublabelData.answer != "" && (subSublabelData.answer == 0 || subSublabelData.answer === 'Unknown')){
																		initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = true;
																	}else{
																		initialValuesArray2[stringifyPathForInitialvalues(partnerFeildName)] = false;
																	}
																}
																/* For checkbox - End */
															})
														})
													}
												}
												// New code - End

											})
										}
									})
								}
							}
						})
					}
				})
			}
		})
		if(joint_client){
		const updatedObject = {};
		for (let key in initialValuesArray1) {
			updatedObject[`partner_${key}`] = initialValuesArray1[key];
		  }
		 var relationKeys = Object.keys(updatedObject)
		  						.filter(a => a.includes('partner_relationship___dependencies') || a.includes('partner_will')|| a.includes('partner_basic_information')|| a.includes('partner_contact_details') || a.includes('partner_property_details') || a.includes('partner_assets'))
		relationKeys.forEach(k => {
			if (updatedObject[k] === ''&&( k=="partner_relationship___dependencies_how_many__38" || k=="partner_relationship___dependencies_do_you_have_children_dependants__37"
			||k=="partner_contact_details_postcode_173"||k=="partner_contact_details_region_172"||k=="partner_contact_details_street_1_169"||k=="partner_contact_details_street_2_170"||k=="partner_contact_details_town_171"||k=="partner_contact_details_flat_174" || k=="partner_property_details_do_you_have_any_outstanding_mortgage__157"
			// ||k=="partner_property_details_in_whose_name__29" 
			|| k=="partner_property_details_cost_of_rent_pm_____146"||  k=="partner_property_details_rent_or_own_property_27"
			||k=="partner_property_details_rent_or_own_property_27"||
			 k=="partner_property_details_do_you_plan_to_stay_at_the_property__102"|| k=="partner_property_details_do_you_plan_to_do_any_renovations__35"
			 || k=="partner_property_details_value_of_the_property_____28"|| k=="partner_property_details_notes_103"|| k=="partner_property_details_interest_only_or_repayment_33" || k=="partner_property_details_interest_rate_34" || k=="partner_property_details_lender_31" 
			 || k=="partner_property_details_mortgage_cost_per_month_____87" || k=="partner_property_details_mortgage_term_remaining__in_years__88"|| k=="partner_property_details_offset_100" || k=="partner_property_details_amount_mortgage_outstanding_____30" || k=="partner_property_details_do_you_have_any_outstanding_mortgage__157"|| k=="partner_property_details_do_you_overpay_the_mortgage__36" ||k=="partner_property_details_tie_in_period_end_date_90"
			 || k=="partner_property_details_how_much______101" )) {
				if(initialValuesArray2[k]!=""){
					updatedObject[k] = initialValuesArray2[k];
				}
				
			}
			if (updatedObject[k]!=''&&( k=="partner_relationship___dependencies_how_many__38" || k=="partner_relationship___dependencies_do_you_have_children_dependants__37"
			||k=="partner_contact_details_postcode_173"||k=="partner_contact_details_region_172"||k=="partner_contact_details_street_1_169"||k=="partner_contact_details_street_2_170"||k=="partner_contact_details_town_171"||k=="partner_contact_details_flat_174" || k=="partner_property_details_do_you_have_any_outstanding_mortgage__157"
			||k=="partner_property_details_in_whose_name__29" 
			|| k=="partner_property_details_cost_of_rent_pm_____146"||  k=="partner_property_details_rent_or_own_property_27"
			||k=="partner_property_details_rent_or_own_property_27"||
			 k=="partner_property_details_do_you_plan_to_stay_at_the_property__102"|| k=="partner_property_details_do_you_plan_to_do_any_renovations__35"
			 || k=="partner_property_details_value_of_the_property_____28"|| k=="partner_property_details_notes_103"|| k=="partner_property_details_interest_only_or_repayment_33" || k=="partner_property_details_interest_rate_34" || k=="partner_property_details_lender_31" 
			 || k=="partner_property_details_mortgage_cost_per_month_____87" || k=="partner_property_details_mortgage_term_remaining__in_years__88"|| k=="partner_property_details_offset_100" || k=="partner_property_details_amount_mortgage_outstanding_____30" || k=="partner_property_details_do_you_have_any_outstanding_mortgage__157"|| k=="partner_property_details_do_you_overpay_the_mortgage__36" ||k=="partner_property_details_tie_in_period_end_date_90"
			 || k=="partner_property_details_how_much______101" ))	{
				if(initialValuesArray2[k]==""){
					initialValuesArray2[k]=updatedObject[k]
				}
				
			 }
			if(Array.isArray(updatedObject[k])&& updatedObject[k].length > 0&&(k=="partner_relationship___dependencies_dob_86" || k=="partner_relationship___dependencies_name_108" || k=="partner_relationship___dependencies_relationship_85" || k=="partner_relationship___dependencies_name__relationship__age_39" || k=="partner_relationship___dependencies_do_you_have_children_dependants__37" || k=="partner_relationship___dependencies_email_180")){
				if(updatedObject[k][0].sub_label_data[0].answer==""&& initialValuesArray2[k]!=""){
				updatedObject[k] = initialValuesArray2[k];
				}
				if(initialValuesArray2[k][0].sub_label_data[0].answer==""&& updatedObject[k]!=""){
				 initialValuesArray2[k]=updatedObject[k] ;
				}
			}
		})
		// for (let key in updatedObject) {
			
			
		// 	// else if (updatedObject[key].length>0){
		// 	// 	updatedObject[key] = initialValuesArray2[key];
		// 	// }
		//   }
		  const updatedObject1 = {};

		for (let key in updatedObject) {
			const updatedKey = key.replace('partner_', '');
			updatedObject1[updatedKey] = updatedObject[key];
		  }
		initialValuesArray={ ...updatedObject1 ,...initialValuesArray2 }
		}
		if(parallelly_added){
			initialValuesArray={ ...initialValuesArray1 ,...initialValuesArray2 }
		}
	}
	if(dispatch){
		dispatch(saveDocConversionSurveyForm({...previousDocConversionData,...docConversionData,}))
	}
	
	// (checkedValues.length > 0) && checkedValues.forEach((item)=>{
	//     initialValuesArray[item.checkboxFieldName] = true;
	// })
	return initialValuesArray;
}
// Funtion to create initial form data - End

// Function for finding question array by using field name
export const getQuestionArrayByFieldName = (fieldName, surveyFormData) => {
	var match = null;
	!_.isEmpty(surveyFormData) && surveyFormData.forEach((category) => {
		if(category&&category.data&&category.data.length > 0){
			category.data.forEach((subcategory) => {
				if(subcategory&&subcategory.subcategory_data&&subcategory.subcategory_data.length > 0){
					subcategory.subcategory_data.forEach((questions) => {
						if(questions.field_name === fieldName){
							match = questions;
						}else if(questions&&questions.has_sublabels){
							(questions.sub_labels.length > 0) && questions.sub_labels.forEach((subLabels)=>{
								(subLabels &&subLabels.sub_label_data&& subLabels.sub_label_data.length > 0) && subLabels.sub_label_data.forEach((subQuestions)=>{
									if(subQuestions.field_name === fieldName){
										match = subQuestions;
									}
								})
							})
						}
					})
				}
			})
		}
	})
	return match;
}

// Function for validating inputs
export const validateField = (fieldValue, fieldItem) => {

	let error='';
	let pattern;
	let value = fieldValue;
	if(fieldValue && (fieldItem.component_type === 'SearchableSelect' || fieldItem.component_type === 'Searchable&Checkbox')){
		value = fieldValue?.option
	}
	if(value && fieldItem.value_type){
		let minLength = fieldItem.min_length ? fieldItem.min_length : 0;
		switch(fieldItem.value_type){
			case "Alphabet":
				pattern = new RegExp("^[A-Za-z\\s]{"+minLength+","+fieldItem.max_length+"}$", "i");
				if(!pattern.test(value)) {
					error = "Please enter a valid answer (Alphabets only)";
				}
			break;
			case "Number":
				pattern = new RegExp("^[0-9]{"+minLength+","+fieldItem.max_length+"}$");
				if(!pattern.test(value)) {
					if(fieldItem.min_length == 0){
						error = "Please enter a valid answer (Number upto "+fieldItem.max_length+" length)";
					}else{
						error = "Please enter a valid answer (Number with min: "+minLength+", upto "+fieldItem.max_length+" length)";
					}
				}
			break;
			case "Alpha numeric":
				pattern = new RegExp("^[A-Za-z0-9&\\s]{"+minLength+","+fieldItem.max_length+"}$", "i");
				let tempValue = value.toString();
				if(!(pattern.test(tempValue) && tempValue.trim().length > 0)) {
					if(fieldItem.min_length == 0){
						error = "Please enter a valid answer (Alphanumeric upto "+fieldItem.max_length+" characters)";
					}else{
						error = "Please enter a valid answer (Alphanumeric with min: "+minLength+", upto "+fieldItem.max_length+" characters)";
					}
				}
			break;
	
			case "Decimal":
				pattern = new RegExp("^[0-9.]{"+minLength+","+fieldItem.max_length+"}$", "i");
				if(!pattern.test(value)) {
					error = "Please enter a valid number (Number can have decimal point)";
				}
			break;
			case "Phone number":
				pattern = new RegExp("^[0-9]{"+minLength+","+fieldItem.max_length+"}$");
				if(!pattern.test(value)) {
					if(fieldItem.min_length == 0){
						error = "Please enter a valid phone number (Max. "+fieldItem.max_length+" digits without country code)";
					}else{
						error = "Please enter a valid phone number ("+fieldItem.min_length+" - "+fieldItem.max_length+" digits without country code)";
					}
				}
			break;
			// case "DOB-legal":
			//     if(!validateAge(value)) {
			//         error = "Please enter a legal age";
			//     }
			// break;
			case "percentage":
				if(!(value >= 0 && value <= 100)) {
					error = "Please enter a valid percentage";
				}
			break;
			case "Past date":
				let dateObj = new Date(value)
				if(!(dateObj < new Date())){
					error = "Please enter a past date";
				}
			break;
			case "Price":
				error = '';
				let string_val = value.toString();
				let formatted_val = string_val.replace(/\,/g,'');
                if(fieldItem.max_length && formatted_val.length > fieldItem.max_length){
                    error = `Maximum ${fieldItem.max_length} digits allowed`;
                }else if(fieldItem.min_length && formatted_val.length < fieldItem.min_length){
					error = `Minimum ${fieldItem.min_length} digits allowed`;
				}
                break;
			case "No validation":
				error = '';
			break;
			default:
				error = '';
			break;
		}
	}
	return error;
}

export const checkClientEmailPhoneExist = async (url, accessToken, parameter) =>{
	let existingUserData = await axios.get(url, {
		headers: { Authtoken: 'Token ' + accessToken},
		params: parameter
	});
	return existingUserData?.data?.data?.alreadyExists;
}

// Function for making options from local storage data if data exists
export const getOptionsForSelectBox = (fieldItem, savedValues, surveyFormData=null)=>{

	if(fieldItem.component_type === 'SingleSelect' && fieldItem.has_local_data && savedValues && savedValues.length > 0)
	{
		const optionsData = [];
		savedValues.forEach( optionItem => {
			if(_.isArray(optionItem.valueSavedFor) && optionItem.valueSavedFor.includes(fieldItem.field_name))
			{
				// value in reducer can be an object or text
				if(_.isObject(optionItem.value))
				{
					optionsData.push(optionItem.value)
				}
				else
				{
					optionsData.push({ id : optionItem.value , option : optionItem.value})
				}
				// optionsData.push({ id : fieldItem.answer , option : fieldItem.answer})
			}
			// Custom logic for both option in whose name / owner questions
			var bothRequiredField = getQuestionArrayByFieldName(optionItem.valueSavedFrom, surveyFormData)
			if(bothRequiredField && bothRequiredField.label_slug === appConfig.survey_forms.both_required_field_slug && bothRequiredField.field_name === optionItem.valueSavedFrom && optionsData.length > 1){
				optionsData.push({ id : "Both" , option : "Both"})
			}
		})
		return optionsData;
	}
	else if(fieldItem.component_type === 'SingleSelect' && fieldItem.has_local_data && savedValues && savedValues.length === 0)
	{
		// case comes when persisted value are cleared ( when its empty ), when user saves details in one pc and access in another pc
		const optionsData = [];
		optionsData.push({ id : fieldItem.answer , option : fieldItem.answer})
		return optionsData;
	}
	else if((fieldItem.component_type === 'Input' || fieldItem.component_type === 'currencyField') && fieldItem.has_local_data && savedValues && savedValues.length > 0)
	{
		let optionsData = null;
		savedValues.forEach( optionItem => {
			if(_.isArray(optionItem.valueSavedFor) && optionItem.valueSavedFor.includes(fieldItem.field_name))
			{
				// value in reducer can be an object or text
				if(_.isObject(optionItem.value))
				{
					optionsData = optionItem.value.option
				}
				else
				{
					optionsData = optionItem.value
				}
			}
		})
		return optionsData;
	}else{
		return fieldItem.label_choice;
	}
}

export const getSubCategoryByFieldName = (fieldName, surveyFormData) => {
	var match = null;
	!_.isEmpty(surveyFormData) && surveyFormData.forEach((category) => {
		if(category.data.length > 0){
			category.data.forEach((subcategory) => {
				if(subcategory.subcategory_data.length > 0){
					subcategory.subcategory_data.forEach((questions) => {
						if(questions.field_name === fieldName){
							match = {"subCatId":subcategory.subcategory_id, "catId":category.category_id}
						}
					})
				}
			})
		}
	})
	return match;
}

export const getFormatedAnswerFromValues = (value) => {
	var formatted = (value !== null && typeof value === 'object' && value.hasOwnProperty("option")) ? value.option : value;
	return formatted;
}

export const formatPostData = (formData, surveyFormData) => {

	!_.isEmpty(surveyFormData) && surveyFormData.forEach((category) => {
		category && (category.data.length > 0) && category.data.forEach((subcategory) => {
			subcategory.subcategory_data && (subcategory.subcategory_data.length > 0) && subcategory.subcategory_data.forEach((question) => {

				if(question.response_required){
					if(Object.keys(formData).includes(question.field_name)){
						question.answer = getFormatedAnswerFromValues(formData[question.field_name]);
					}else{
						question.answer = "";
					}
				}
				if(question.has_sublabels){

					if(question.is_repeat){

						// question.sub_labels = formData[question.field_name];
						let questions = [...formData[question.field_name]]
						questions.forEach((questionItem)=>{
							questionItem.sub_label_data && questionItem.sub_label_data.length > 0 && questionItem.sub_label_data.forEach((questionQue)=>{
								questionQue.answer = getFormatedAnswerFromValues(questionQue.answer);
							})
						})
						questions.sub_labels = questions;

					}else{
						question.sub_labels && (question.sub_labels.length > 0) && question.sub_labels.forEach((questionSublabel) => {
							questionSublabel.sub_label_data && (questionSublabel.sub_label_data.length > 0) && questionSublabel.sub_label_data.forEach((subquestion) => {

								if(subquestion.is_repeat){
									// subquestion.sub_labels = formData[subquestion.field_name];
									let subSubQuestions = [...formData[subquestion.field_name]]
									subSubQuestions.forEach((subSubitem)=>{
										subSubitem.sub_label_data && subSubitem.sub_label_data.length > 0 && subSubitem.sub_label_data.forEach((subSubQue)=>{
											subSubQue.answer = getFormatedAnswerFromValues(subSubQue.answer);
										})
									})
									subquestion.sub_labels = subSubQuestions;
								}else{
									if(Object.keys(formData).includes(subquestion.field_name)){
										subquestion.answer = getFormatedAnswerFromValues(formData[subquestion.field_name]);
									}else{
										subquestion.answer = "";
									}
								}
								// New code - Start
								if(subquestion.has_sublabels){
									subquestion.sub_labels && (subquestion.sub_labels.length > 0) && subquestion.sub_labels.forEach((subQuestionSublabel) => {
										subQuestionSublabel.sub_label_data && (subQuestionSublabel.sub_label_data.length > 0) && subQuestionSublabel.sub_label_data.forEach((subSubquestion) => {

											if(!subquestion.is_repeat){
												if(Object.keys(formData).includes(subSubquestion.field_name)){
													subSubquestion.answer = getFormatedAnswerFromValues(formData[subSubquestion.field_name]);
												}else{
													subSubquestion.answer = "";
												}
											}
										})
									})
								}
								// New code - End
							})
						})
					}
				}
			})
		})
	})

	return surveyFormData;
}
export const partnerFormatPostData = (formData, surveyFormData) => {

	!_.isEmpty(surveyFormData) && surveyFormData.forEach((category) => {
		category && (category.data.length > 0) && category.data.forEach((subcategory) => {
			subcategory.subcategory_data && (subcategory.subcategory_data.length > 0) && subcategory.subcategory_data.forEach((question) => {
                   var  partnerFeild_name = "partner_"+question.field_name
				if(question.response_required){
					if(Object.keys(formData).includes(partnerFeild_name)){
						question.answer = getFormatedAnswerFromValues(formData[partnerFeild_name]);
					}else{
						question.answer = "";
					}
				}
				if(question.has_sublabels){

					if(question.is_repeat){

						// question.sub_labels = formData[question.field_name];
						let questions = [...formData[partnerFeild_name]]
						questions.forEach((questionItem)=>{
							questionItem.sub_label_data && questionItem.sub_label_data.length > 0 && questionItem.sub_label_data.forEach((questionQue)=>{
								questionQue.answer = getFormatedAnswerFromValues(questionQue.answer);
							})
						})
						questions.sub_labels = questions;

					}else{
						question.sub_labels && (question.sub_labels.length > 0) && question.sub_labels.forEach((questionSublabel) => {
							questionSublabel.sub_label_data && (questionSublabel.sub_label_data.length > 0) && questionSublabel.sub_label_data.forEach((subquestion) => {
                                    var partner_subFeild_name ="partner_"+subquestion.field_name
								if(subquestion.is_repeat){
									// subquestion.sub_labels = formData[subquestion.field_name];
									let subSubQuestions = [...formData[partner_subFeild_name]]
									subSubQuestions.forEach((subSubitem)=>{
										subSubitem.sub_label_data && subSubitem.sub_label_data.length > 0 && subSubitem.sub_label_data.forEach((subSubQue)=>{
											subSubQue.answer = getFormatedAnswerFromValues(subSubQue.answer);
										})
									})
									subquestion.sub_labels = subSubQuestions;
								}else{
									if(Object.keys(formData).includes(partner_subFeild_name)){
										subquestion.answer = getFormatedAnswerFromValues(formData[partner_subFeild_name]);
									}else{
										subquestion.answer = "";
									}
								}
								// New code - Start
								if(subquestion.has_sublabels){
									subquestion.sub_labels && (subquestion.sub_labels.length > 0) && subquestion.sub_labels.forEach((subQuestionSublabel) => {
										subQuestionSublabel.sub_label_data && (subQuestionSublabel.sub_label_data.length > 0) && subQuestionSublabel.sub_label_data.forEach((subSubquestion) => {
											var partner_subsubFeild_name ="partner_"+subSubquestion.field_name
											if(!subquestion.is_repeat){
												if(Object.keys(formData).includes(partner_subsubFeild_name)){
													subSubquestion.answer = getFormatedAnswerFromValues(formData[partner_subsubFeild_name]);
												}else{
													subSubquestion.answer = "";
												}
											}
										})
									})
								}
								// New code - End
							})
						})
					}
				}
			})
		})
	})

	return surveyFormData;
}
export const checkChildError = (data, formikErrors) => {
    let errorCount = 0;
    data && data.forEach((question)=>{
        if(formikErrors && formikErrors[question.field_name] && formikErrors[question.field_name] != ""){
            errorCount++;
        }
        if(question.has_sublabels){
            question.sub_labels.forEach((subLabel) =>{
                (subLabel&&subLabel.sub_label_data&&subLabel.sub_label_data.length > 0) && subLabel.sub_label_data.forEach((subItem) => {
                    if(formikErrors[subItem.field_name] && formikErrors[subItem.field_name]!=""){
                        errorCount++;
                    }
                    if(subItem.has_sublabels){
                        subItem.sub_labels && subItem.sub_labels.forEach((subSubLabel) =>{
                            subSubLabel.sub_label_data && subSubLabel.sub_label_data.forEach((subSubItem) => {
                                if(formikErrors[subSubItem.field_name] && formikErrors[subSubItem.field_name]!=""){
                                    errorCount++;
                                }
                                // New code - Start
                                if(subSubItem.has_sublabels){
                                    subSubItem.sub_labels && subSubItem.sub_labels.forEach((subSubsubLabel) =>{
                                        subSubsubLabel.sub_label_data && subSubsubLabel.sub_label_data.forEach((subSubsubItem) => {
                                            if(formikErrors[subSubsubItem.field_name] && formikErrors[subSubsubItem.field_name]!=""){
                                                errorCount++;
                                            }
                                        })
                                    })
                                }
                                // New code - End
                            })
                        })
                    }
                })
            })
        }
    })
    if(errorCount > 0){
        return true;
    }else{
        return false;
    }
}

export const checkChildFilled = (data, formikValues) => {
    let filledCount;
    let subLabelCount;
    if(data.has_sublabels && data.response_required === false){
        data.sub_labels && data.sub_labels.forEach((subLabel) =>{
            filledCount = 0;
            subLabelCount = 0;
            subLabelCount = subLabel.sub_label_data.length;
            subLabel.sub_label_data && subLabel.sub_label_data.forEach((subItem) => {
                if(formikValues[subItem.field_name]!="" || parseInt(formikValues[subItem.field_name]) >= 0){
                    filledCount++;
                }
            })
        })
    }
    else if(data.has_sublabels && data.response_required && formikValues[data.field_name] && formikValues[data.field_name]!=""){

        filledCount = 0;
        subLabelCount = 1;
        if(formikValues[data.field_name]!="" || parseInt(formikValues[data.field_name]) >= 0){
            filledCount++;
        }
        data.sub_labels && data.sub_labels.forEach((subLabel) =>{

            subLabel.sub_label_data && subLabel.sub_label_data.forEach((subItem) => {

                if(subItem.is_repeat){

                    (formikValues[subItem.field_name].length > 0) && formikValues[subItem.field_name].forEach((repeatItem, repeatItemIndex)=>{
                        repeatItem.sub_label_data && repeatItem.sub_label_data.forEach((subQuestionItem, subQuestionItemIndex)=>{
							var answerParent1 =(subQuestionItem.answer_parent!=undefined)?subQuestionItem.answer_parent:['Yes']
                            var answerParent = answerParent1.map((item) => {return item.toLowerCase()})
                            var selectedAnswer;
                            if(formikValues[data.field_name]){
                                if(_.isArray(formikValues[data.field_name])){
                                    selectedAnswer = formikValues[data.field_name].map((item) => {
                                        return item.option.toLowerCase()
                                    })
                                }else if(_.isObject(formikValues[data.field_name])){
                                    selectedAnswer = formikValues[data.field_name].option.toLowerCase()
                                }else{
                                    selectedAnswer = formikValues[data.field_name].toLowerCase()
                                }
                            }
                            if(formikValues[data.field_name] && answerParent.includes(selectedAnswer)){
                                subLabelCount++;
                                if(subQuestionItem.answer !="" || parseInt(subQuestionItem.answer) >= 0){
                                    filledCount++;
                                }
                            }
                        })
                    })
                }
                else{
					var answerParent1 = (subItem.answer_parent!=undefined)?subItem.answer_parent:['Yes']
                    var answerParent = answerParent1.map((item) => {return item.toLowerCase()})
                    var selectedAnswer;
                    if(formikValues[data.field_name]){
                        if(_.isArray(formikValues[data.field_name])){
                            selectedAnswer = formikValues[data.field_name].map((item) => {
                                return item.option.toLowerCase()
                            })
                        }else if(_.isObject(formikValues[data.field_name])){
                            selectedAnswer = formikValues[data.field_name].option.toLowerCase()
                        }else{
                            selectedAnswer = formikValues[data.field_name].toLowerCase()
                        }
                    }
                    if(subItem.response_required && formikValues[data.field_name] && answerParent.includes(selectedAnswer)){
                        subLabelCount++;
                        if(formikValues[subItem.field_name]!="" || parseInt(formikValues[subItem.field_name]) >= 0){
                            filledCount++;
                        }
                    }

                    // New code - Start
                    if(subItem.has_sublabels){
                        subItem.sub_labels && subItem.sub_labels.forEach((subSubLabel) =>{

                            subSubLabel.sub_label_data && subSubLabel.sub_label_data.forEach((subSubItem) => {
                                var answerParent1 = (subSubItem.answer_parent!=undefined)?subSubItem.answer_parent:['Yes']
                                var answerParent = answerParent1.map((item) => {return item.toLowerCase()})
                                var selectedAnswer;
                                if(formikValues[subItem.field_name]){
                                    if(_.isArray(formikValues[subItem.field_name])){
                                        selectedAnswer = formikValues[subItem.field_name].map((item) => {
                                            return item.option.toLowerCase()
                                        })
                                    }else if(_.isObject(formikValues[subItem.field_name])){
                                        selectedAnswer = formikValues[subItem.field_name].option.toLowerCase()
                                    }else{
                                        selectedAnswer = formikValues[subItem.field_name].toLowerCase()
                                    }
                                }

                                // if(subSubItem.response_required && formikValues[subItem.field_name] && answerParent.includes(selectedAnswer)){
                                if(formikValues[subItem.field_name] && answerParent.includes(selectedAnswer)){
                                    subLabelCount++;
                                    if(formikValues[subSubItem.field_name]!="" || parseInt(formikValues[subSubItem.field_name]) >= 0){
                                        filledCount++;
                                    }
                                }

                            })
                        })
                    }
                    // New code - End
                }
            })
        })
    }
    if(filledCount && subLabelCount && filledCount == subLabelCount){
        return true;
    }else{
        return false;
    }
}


// Utility functions for survey forms - End

/**
 * Function for finding doc_type using key
 */
export const getDocType = (key) => {
	let documentType = DOC_TYPES.filter(item => item.key === key);
	if(!_.isEmpty(documentType)){
		return documentType[0]?.id;
	}
	return false;
}
// End function


export const isNOTNullOrUndefined = value => {
	return !isNullOrUndefined(value)
  }
  
  export const isNullOrUndefined = value => {
	return (
	  value === null ||
	  value === undefined ||
	  value === '' ||
	  value === 'null' ||
	  value === 'undefined'
	)
  }
  
  export const truncateString = (str, limit) => {
	if (isNullOrUndefined(str)) {
	  return str
	}
  
	if (limit === null) {
	  limit =  10
	}
	if (str.length > limit) {
	  return str.slice(0, limit) + '...'
	} else {
	  return str
	}
  }

  export function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export function formatNumber(value) {
	if(!value) return ''
    if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + 'm';
    } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'k';
    } else {
        return value.toString();
    }
}

export const isNotProduction =()=>{
	return process.env.REACT_APP_BASE_URL === "https://dev.thisisdavid.tech/api/" || process.env.REACT_APP_BASE_URL === "https://qa.thisisdavid.tech/api/" || process.env.REACT_APP_BASE_URL === "https://test.thisisdavid.tech/api/"
}