import { TASKS_TYPES } from './tasks.types';
import { clearTaskState } from './tasks.utils';

const INITIAL_STATE = {
    fetchFilteredTaskListLoading: false,
    filteredTaskList: [],
    filteredTaskListSuccessMsg: null,
    filteredTaskListErrorMsg: null,

    addTaskCommentLoading: false,
    addTaskCommentErrorMsg: null,
    addTaskCommentsSuccessMsg: null,

    isTaskCommentsFetching: false,
    taskComments: null,
    taskCommentsSuccessMsg: null,
    taskCommentsErrorMsg: null,

    isUserBasedOnParamsFetching: false,
    userListBasedOnParams: null,
    userListBasedOnParamsSuccessMsg: null,
    userListBasedOnParamsErrMsg: null,

    isCurrentTaskMeetingFetching: false,
    currentTaskMeetingData: null,

    isTaskStatusBarFetching : false,
    taskStatusBarDetails : null,
    taskStatusBarErrorMsg : null,

    isTimelineFetching : false,
    taskTimeline : null,
    taskTimelineErrorMsg : null,

    isStaffFetching : false,
    staffList : null,
    staffListErrorMsg : null,

    isSendFeedbackLoading : false,
    sendFeedbackErrorMsg : null,
    sendFeedbackSuccessMsg : null,

    isVerifyTaskLoading : false,
    verifyTaskErrorMsg : null,
    verifyTaskSuccessMsg : null,

    isAssignTaskLoading : false,
    assignTaskSuccessMsg : null,
    assignTaskErrorMsg : null,

    isMentionStaffLoading : false,
    mentionStaffList : null,

    filteredTaskListCount : null,
    filteredTaskListOffset : null,

    
    isKYCDataFetching : false,
    KYCErrorMsg : null,
    KYCData : null,

    isLoadingReferToSuccess : false,
    referToSuccessResponse : null,
    referToSuccessErrorMsg : null
}



const tasksReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {



        case TASKS_TYPES.FETCH_FILTERED_TASK_LIST_START:
            return {
                ...state,
                fetchFilteredTaskListLoading: true,
            }
        case TASKS_TYPES.FETCH_FILTERED_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchFilteredTaskListLoading: false,
                filteredTaskList: payload.data,
                filteredTaskListCount : payload?.count ? payload.count : 0,
                filteredTaskListOffset : payload?.offset ? payload.offset : 0,
            }
        case TASKS_TYPES.FETCH_FILTERED_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchFilteredTaskListLoading: false,
            }
        case TASKS_TYPES.CLEAR_FETCH_FILTERED_TASK_LIST_SUCCESS_MSG:
            return {
                ...state,
                filteredTaskListSuccessMsg: null,
            }
        case TASKS_TYPES.CLEAR_FETCH_FILTERED_TASK_LIST_ERROR_MSG:
            return {
                ...state,
                filteredTaskListErrorMsg: null,
            }


        case TASKS_TYPES.ADD_TASK_COMMENT_START:
            return {
                ...state,
                addTaskCommentLoading: true,
            }
        case TASKS_TYPES.ADD_TASK_COMMENT_SUCCESS:
            return {
                ...state,
                addTaskCommentLoading: false,
                addTaskCommentsSuccessMsg: payload.message,
                taskComments: payload.data
            }
        case TASKS_TYPES.ADD_TASK_COMMENT_FAILURE:
            return {
                ...state,
                addTaskCommentLoading: false,
                addTaskCommentErrorMsg: payload.message,
            }
        case TASKS_TYPES.CLEAR_TASK_COMMENT_ERROR_MSG:
            return {
                ...state,
                addTaskCommentErrorMsg: null
            }
        case TASKS_TYPES.CLEAR_TASK_COMMENT_SUCCESS_MSG:
            return {
                ...state,
                addTaskCommentsSuccessMsg: null
            }



        case TASKS_TYPES.FETCH_TASK_COMMENTS_START:
            return {
                ...state,
                isTaskCommentsFetching: true,
            }
        case TASKS_TYPES.FETCH_TASK_COMMENTS_SUCCESS:
            return {
                ...state,
                isTaskCommentsFetching: false,
                taskComments: payload.data,
                taskCommentsSuccessMsg: payload.message
            }
        case TASKS_TYPES.FETCH_TASK_COMMENTS_FAILURE:
            return {
                ...state,
                isTaskCommentsFetching: false,
                taskCommentsErrorMsg: payload.message
            }



        case TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_START:
            return {
                ...state,
                isUserBasedOnParamsFetching: true,
            }
        case TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_SUCCESS:
            return {
                ...state,
                isUserBasedOnParamsFetching: false,
                userListBasedOnParams: payload.data,
                userListBasedOnParamsSuccessMsg: payload.message
            }
        case TASKS_TYPES.FETCH_USERS_BASED_ON_PARAMS_FAILURE:
            return {
                ...state,
                isUserBasedOnParamsFetching: false,
                userListBasedOnParamsErrMsg: payload.message
            }


        case TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_START:
            return {
                ...state,
                isCurrentTaskMeetingFetching: true,
            }
        case TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_SUCCESS:
            return {
                ...state,
                isCurrentTaskMeetingFetching: false,
                currentTaskMeetingData: payload.data,
                currentTaskMeetingSuccessMsg: payload.data,
            }
        case TASKS_TYPES.FETCH_CURRENT_TASK_MEETING_FAILURE:
            return {
                ...state,
                isCurrentTaskMeetingFetching: false,
                currentTaskMeetingErrorMsg: payload.data,
            }
        case TASKS_TYPES.CLEAR_CURRENT_TASK_MEETING_ERROR_MSG:
            return {
                ...state,
                currentTaskMeetingErrorMsg: null,
                verifyTaskErrorMsg : null,
                verifyTaskSuccessMsg : null,
                assignTaskSuccessMsg : null,
                assignTaskErrorMsg : null,
                referToSuccessResponse : null,
                referToSuccessErrorMsg : null
            }
        



        case TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_START:
            return {
                ...state,
                isTaskStatusBarFetching: true,
            }
        case TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_SUCCESS:
            return {
                ...state,
                isTaskStatusBarFetching :  false,
                taskStatusBarDetails: payload.data,
            }
        case TASKS_TYPES.FETCH_TASK_STATUSBAR_DETAILS_FAILURE:
            return {
                ...state,
                isTaskStatusBarFetching: false,
                taskStatusBarErrorMsg: payload.message
            }


        case TASKS_TYPES.FETCH_TASK_TIMELINE_START:
            return {
                ...state,
                isTimelineFetching: true,
            }
        case TASKS_TYPES.FETCH_TASK_TIMELINE_SUCCESS:
            return {
                ...state,
                isTimelineFetching :  false,
                taskTimeline: payload.data,
            }
        case TASKS_TYPES.FETCH_TASK_TIMELINE_FAILURE:
            return {
                ...state,
                isTimelineFetching: false,
                taskTimelineErrorMsg: payload.message
            }

        case TASKS_TYPES.FETCH_STAFF_START:
            return {
                ...state,
                isStaffFetching: true,
            }
        case TASKS_TYPES.FETCH_STAFF_SUCCESS:
            return {
                ...state,
                isStaffFetching :  false,
                staffList: payload.data,
            }
        case TASKS_TYPES.FETCH_STAFF_FAILURE:
            return {
                ...state,
                isStaffFetching: false,
                staffListErrorMsg: payload.message
            }
       case TASKS_TYPES.PUT_SEND_FEEDBACK_START:
            return {
                ...state,
                isSendFeedbackLoading: true,
                }
        case TASKS_TYPES.PUT_SEND_FEEDBACK_SUCCESS:
            return {
                ...state,
                isSendFeedbackLoading :  false,
                taskStatusBarDetails : payload.data,
                sendFeedbackSuccessMsg: payload.message
                }
        case TASKS_TYPES.PUT_SEND_FEEDBACK_FAILURE:
            return {
                ...state,
                isSendFeedbackLoading: false,
                sendFeedbackErrorMsg: payload?.data?.task ? payload?.data?.task[0] : payload.message
                }
        case TASKS_TYPES.PUT_VERIFY_START:
            return {
                ...state,
                isVerifyTaskLoading: true,
            }
        case TASKS_TYPES.PUT_VERIFY_SUCCESS:
            return {
                ...state,
                isVerifyTaskLoading :  false,
                taskStatusBarDetails : payload.data,
                verifyTaskSuccessMsg: payload.message
            }
        case TASKS_TYPES.PUT_VERIFY_FAILURE:
            return {
                ...state,
                isVerifyTaskLoading: false,
                verifyTaskErrorMsg: payload?.data?.task ? payload?.data?.task[0] : payload.message
            }

        case TASKS_TYPES.POST_ASSIGN_TASK_START:
            return {
                ...state,
                isAssignTaskLoading: true,
            }
        case TASKS_TYPES.POST_ASSIGN_TASK_SUCCESS:
            return {
                ...state,
                isAssignTaskLoading :  false,
                assignTaskSuccessMsg: payload.message
            }
        case TASKS_TYPES.POST_ASSIGN_TASK_FAILURE:
            return {
                ...state,
                isAssignTaskLoading: false,
                assignTaskErrorMsg: payload.message
            }


        case TASKS_TYPES.FETCH_MENTION_STAFF_START:
            return {
                ...state,
                isMentionStaffLoading: true,
            }
        case TASKS_TYPES.FETCH_MENTION_STAFF_SUCCESS:
            return {
                ...state,
                isMentionStaffLoading :  false,
                mentionStaffList: payload.data
            }
        case TASKS_TYPES.FETCH_MENTION_STAFF_FAILURE:
            return {
                ...state,
                isMentionStaffLoading: false,
                mentionStaffListErrorMsg: payload.message
            }



            case TASKS_TYPES.VERIFY_CHECK_LIST_START:
            return {
                ...state,
                 isVerifyCheckListFetching: true,
            }
            case TASKS_TYPES.VERIFY_CHECK_LIST_SUCCESS:
            return {
                ...state,
                isVerifyCheckListFetching: false,
                verifyCheckListSuccessMsg : payload.message,
                verifyCheckListErrMsg : null,
                taskStatusBarDetails : payload.data
            }
            case TASKS_TYPES.VERIFY_CHECK_LIST_FAILURE:
            return {
                ...state,
                isVerifyCheckListFetching: false,
                verifyCheckListErrMsg: payload.message,
                verifyCheckListSuccessMsg : null
            }
            case TASKS_TYPES.CLEAR_VERIFY_CHECK_LIST_ERROR_MESSAGE:
            return {
                ...state,
                verifyCheckListErrMsg: null,
            }
            case TASKS_TYPES.CLEAR_VERIFY_CHECK_LIST_SUCCESS_MESSAGE:
            return {
                ...state,
                verifyCheckListSuccessMsg: null,
            }

            case TASKS_TYPES.GET_KYC_DATA_START:
            return {
                ...state,
                isKYCDataFetching: true,
                KYCErrorMsg : null,
            }
            case TASKS_TYPES.GET_KYC_DATA_SUCCESS:
            return {
                ...state,
                isKYCDataFetching: false,
                KYCData : payload.data,
                taskStatusBarDetails : {
                    ...state.taskStatusBarDetails,
                    is_kyc_confirmed : payload.data.is_kyc_confirmed
                }
            }
            case TASKS_TYPES.GET_KYC_DATA_FAILURE:
            return {
                ...state,
                isKYCDataFetching: false,
                KYCErrorMsg : payload.message,
                KYCData : null,
            }


            // already generated KYC data also updates the same state 
            case TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_START:
                return {
                    ...state,
                    isKYCDataFetching: true,
                    KYCErrorMsg : null,
                    KYCData : null
                }
                case TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_SUCCESS:
                return {
                    ...state,
                    isKYCDataFetching: false,
                    KYCData : payload.data,
                }
                case TASKS_TYPES.GET_ALREADY_GENERATED_KYC_DATA_FAILURE:
                return {
                    ...state,
                    isKYCDataFetching: false,
                    KYCErrorMsg : payload.message,
                    KYCData : null
                }

            case TASKS_TYPES.CLEAR_TASK_REDUCER_STATE :
                return clearTaskState(payload, state)

            case TASKS_TYPES.PUT_REFER_TO_SUCCESS_START:
            return {
                ...state,
                isLoadingReferToSuccess: true,
            }
            case TASKS_TYPES.PUT_REFER_TO_SUCCESS_SUCCESS:
            return {
                ...state,
                isLoadingReferToSuccess: false,
                referToSuccessResponse: payload.message
            }
            case TASKS_TYPES.PUT_REFER_TO_SUCCESS_FAILURE:
            return {
                ...state,
                isLoadingReferToSuccess: false,
                referToSuccessErrorMsg : payload.message,
            }
    
        default:
            return state;
    }
}


export default tasksReducer;