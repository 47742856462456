export const TASKS_TYPES = {
    FETCH_FILTERED_TASK_LIST_START: 'FETCH_FILTERED_TASK_LIST_START',
    FETCH_FILTERED_TASK_LIST_SUCCESS: 'FETCH_FILTERED_TASK_LIST_SUCCESS',
    FETCH_FILTERED_TASK_LIST_FAILURE: 'FETCH_FILTERED_TASK_LIST_FAILURE',
    CLEAR_FETCH_FILTERED_TASK_LIST_SUCCESS_MSG: 'CLEAR_FETCH_FILTERED_TASK_LIST_SUCCESS_MSG',
    CLEAR_FETCH_FILTERED_TASK_LIST_ERROR_MSG: 'CLEAR_FETCH_FILTERED_TASK_LIST_ERROR_MSG',


    ADD_TASK_COMMENT_START: 'ADD_TASK_COMMENT_START',
    ADD_TASK_COMMENT_SUCCESS: 'ADD_TASK_COMMENT_SUCCESS',
    ADD_TASK_COMMENT_FAILURE: 'ADD_TASK_COMMENT_FAILURE',
    CLEAR_TASK_COMMENT_ERROR_MSG: 'CLEAR_TASK_COMMENT_ERROR_MSG',
    CLEAR_TASK_COMMENT_SUCCESS_MSG: 'CLEAR_TASK_COMMENT_SUCCESS_MSG',

    FETCH_TASK_COMMENTS_START : 'FETCH_TASK_COMMENTS_START',
    FETCH_TASK_COMMENTS_SUCCESS : 'FETCH_TASK_COMMENTS_SUCCESS',
    FETCH_TASK_COMMENTS_FAILURE : 'FETCH_TASK_COMMENTS_FAILURE',


    FETCH_USERS_BASED_ON_PARAMS_START : 'FETCH_USERS_BASED_ON_PARAMS_START',
    FETCH_USERS_BASED_ON_PARAMS_SUCCESS : 'FETCH_USERS_BASED_ON_PARAMS_SUCCESS',
    FETCH_USERS_BASED_ON_PARAMS_FAILURE : 'FETCH_USERS_BASED_ON_PARAMS_FAILURE',


    FETCH_CURRENT_TASK_MEETING_START : 'FETCH_CURRENT_TASK_MEETING_START',
    FETCH_CURRENT_TASK_MEETING_SUCCESS : 'FETCH_CURRENT_TASK_MEETING_SUCCESS',
    FETCH_CURRENT_TASK_MEETING_FAILURE : 'FETCH_CURRENT_TASK_MEETING_FAILURE',
    CLEAR_CURRENT_TASK_MEETING_ERROR_MSG : 'CLEAR_CURRENT_TASK_MEETING_ERROR_MSG',
    CLEAR_CURRENT_TASK_MEETING_SUCCESS_MSG : 'CLEAR_CURRENT_TASK_MEETING_SUCCESS_MSG',


    FETCH_TASK_STATUSBAR_DETAILS_START : 'FETCH_TASK_STATUSBAR_DETAILS_START',
    FETCH_TASK_STATUSBAR_DETAILS_SUCCESS : 'FETCH_TASK_STATUSBAR_DETAILS_SUCCESS',
    FETCH_TASK_STATUSBAR_DETAILS_FAILURE : 'FETCH_TASK_STATUSBAR_DETAILS_FAILURE',

    FETCH_TASK_TIMELINE_START : 'FETCH_TASK_TIMELINE_START',
    FETCH_TASK_TIMELINE_SUCCESS : 'FETCH_TASK_TIMELINE_SUCCESS',
    FETCH_TASK_TIMELINE_FAILURE : 'FETCH_TASK_TIMELINE_FAILURE',

    FETCH_STAFF_START : 'FETCH_STAFF_START',
    FETCH_STAFF_SUCCESS : 'FETCH_STAFF_SUCCESS',
    FETCH_STAFF_FAILURE : 'FETCH_STAFF_FAILURE',

    PUT_VERIFY_START : 'PUT_VERIFY_START',
    PUT_VERIFY_SUCCESS : 'PUT_VERIFY_SUCCESS',
    PUT_VERIFY_FAILURE : 'PUT_VERIFY_FAILURE',

    PUT_SEND_FEEDBACK_START:'PUT_SEND_FEEDBACK_START',
    PUT_SEND_FEEDBACK_SUCCESS:'PUT_SEND_FEEDBACK_SUCCESS',
    PUT_SEND_FEEDBACK_FAILURE:'PUT_SEND_FEEDBACK_FAILURE',

    POST_ASSIGN_TASK_START : 'POST_ASSIGN_TASK_START',
    POST_ASSIGN_TASK_SUCCESS : 'POST_ASSIGN_TASK_SUCCESS',
    POST_ASSIGN_TASK_FAILURE : 'POST_ASSIGN_TASK_FAILURE',

    FETCH_MENTION_STAFF_START : 'FETCH_MENTION_STAFF_START',
    FETCH_MENTION_STAFF_SUCCESS : 'FETCH_MENTION_STAFF_SUCCESS',
    FETCH_MENTION_STAFF_FAILURE : 'FETCH_MENTION_STAFF_FAILURE',
    
    VERIFY_CHECK_LIST_START : 'VERIFY_CHECK_LIST_START',
    VERIFY_CHECK_LIST_SUCCESS : 'VERIFY_CHECK_LIST_SUCCESS',
    VERIFY_CHECK_LIST_FAILURE : 'VERIFY_CHECK_LIST_FAILURE',
    CLEAR_VERIFY_CHECK_LIST_ERROR_MESSAGE : 'CLEAR_VERIFY_CHECK_LIST_ERROR_MESSAGE',
    CLEAR_VERIFY_CHECK_LIST_SUCCESS_MESSAGE : 'CLEAR_VERIFY_CHECK_LIST_SUCCESS_MESSAGE',

    
    GET_KYC_DATA_START : 'GET_KYC_DATA_START',
    GET_KYC_DATA_SUCCESS : 'GET_KYC_DATA_SUCCESS',
    GET_KYC_DATA_FAILURE : 'GET_KYC_DATA_FAILURE',


    GET_ALREADY_GENERATED_KYC_DATA_START : 'GET_ALREADY_GENERATED_KYC_DATA_START',
    GET_ALREADY_GENERATED_KYC_DATA_SUCCESS : 'GET_ALREADY_GENERATED_KYC_DATA_SUCCESS',
    GET_ALREADY_GENERATED_KYC_DATA_FAILURE : 'GET_ALREADY_GENERATED_KYC_DATA_FAILURE',

    CLEAR_TASK_REDUCER_STATE : 'CLEAR_TASK_REDUCER_STATE',

    PUT_REFER_TO_SUCCESS_START : 'PUT_REFER_TO_SUCCESS_START',
    PUT_REFER_TO_SUCCESS_SUCCESS : 'PUT_REFER_TO_SUCCESS_SUCCESS',
    PUT_REFER_TO_SUCCESS_FAILURE : 'PUT_REFER_TO_SUCCESS_FAILURE'
}