import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { logOutUserAsync } from '../../../redux/auth/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { removeErrorMessage, setNotificationCountAsync, clearNotificationCount } from '../../../redux/auth/auth.action';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import NameCircle from '../../NameCircle/NameCircle';
import Badge from '@material-ui/core/Badge';
import remindernew from "../../../assets/notificationBellIcon.png"
import { checkPermissions, splitBySpaceAndGetFirstTwoLetters } from '../../../utils/utils';
import './Header.scss';


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        color: theme.palette.common.black,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        position: 'absolute',
        width: `calc(100%)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('1367')]: {  // for 1367 onwards width will be 350px
            marginLeft: '320px',
        },
        [theme.breakpoints.up('1600')]: {
            marginLeft: '430px',
        },
        [theme.breakpoints.up('1919')]: {
            marginLeft: '580px',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));




const Header = ({ handleDrawerOpen, isSidebarExpanded }) => {



    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const currentUserData = useSelector(state => state.auth.currentUserData);
    //  const currentUserData ={first_name:'test',last_name:'1',permission_list:[]}

    const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
    const errorData = useSelector(state => state.auth.errorData);
    const userData = useSelector(state => state.auth.currentUserData);
    useEffect(() => {
        dispatch(removeErrorMessage())
        if(isUserLoggedIn)
        {
            dispatch(setNotificationCountAsync())
        }
    }, [isUserLoggedIn]);
    const dispatch = useDispatch();
    const classes = useStyles();

 //   const firstName = useSelector(state => state.auth.currentUserData.first_name);
  //  const lastName = useSelector(state => state.auth.currentUserData.last_name);
    const notificationCount = useSelector(state => state.auth.notificationCount);
    let fullName;
    if(userData){
        fullName = `${userData.first_name} ${userData.last_name}`;
    }

    useEffect(() => {
        if (errorData)
        {
            enqueueSnackbar(errorData, {
                variant: 'default',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
    }, [errorData])

  

    useEffect(() => {
        if (!currentUserData)
        {
            history.push("/");
        }
    }, [currentUserData])



    // --- starts  : Managing profile section in header -- 

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };



    const menuId = 'cap-profile-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className="desktopMenu"
        >
            <MenuItem
                onClick={() => {
                    history.push('/profile-settings')
                    handleMenuClose();
                }}
            >
                Profile Settings
                </MenuItem>

            <MenuItem
                onClick={() => {
                    history.push('/reset-password')
                    handleMenuClose();
                }}
            >
                Reset Password
            </MenuItem>
            <MenuItem
                onClick={() => {
                    dispatch(logOutUserAsync());
                    dispatch(clearNotificationCount(0));
                    handleMenuClose();
                }}
            >
                Log out
            </MenuItem>
        </Menu>
    );



    const mobileMenuId = 'cap-profile-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <div className="mobileMenu">
                {
                    checkPermissions(currentUserData.permission_list, ['REMINDERS-VIEW'])
                    &&
                    <MenuItem className="mobileMenuItem" onClick={() => history.push('/reminders')}>
                        <IconButton aria-label="show 11 new notifications" color="inherit" >
                            {
                                notificationCount > 0
                                ?
                                <Badge badgeContent={notificationCount} color="secondary">
                                     <img
                                src={remindernew}
                                alt=""
                                width={35}
                              />
                                </Badge>
                                :
                                <img
                                src={remindernew}
                                alt=""
                                width={35}
                              />
                            }
                        </IconButton>
                        <p>Reminders</p>
                    </MenuItem>
                }
                

                <MenuItem onClick={handleProfileMenuOpen} className="mobileMenuItem">
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <NameCircle size="38px" style={{ fontSize: '14px', marginRight: '0' }} name={splitBySpaceAndGetFirstTwoLetters(fullName)} />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </div>
        </Menu>
    );

    // --- Ends  : Managing profile section in header -- 


    return (
        <>
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, 'header', {
                    [classes.appBarShift]: isSidebarExpanded,
                })}
            >
                <div >
                    <Toolbar className="toolbarWrapper">
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                        >
                            <MenuIcon className="hamburgerIcon" />
                        </IconButton>
                        <div className={classes.grow} />
                        <div className="d-flex flex-column">
                            <p className="m-0">{userData.first_name} {userData.last_name}</p>
                            <p className="m-0">Role: {userData.logged_in_user_type}</p>
                        </div>
                        <div className={`desktopHeaderIconsWrapper ${classes.sectionDesktop}`}>
                            {
                                checkPermissions(currentUserData.permission_list, ['REMINDERS-VIEW'])
                                &&
                                <IconButton disableRipple={true} aria-label={`show ${notificationCount} new notifications`} className="headerIcon headerIcon--mod" color="inherit" onClick={() => history.push('/reminders')}>
                                    {
                                        notificationCount > 0
                                            ?
                                            <Badge badgeContent={notificationCount} color="secondary">
                                            <img
                                            src={remindernew}
                                            alt=""
                                            width={35}
                                            />
                                            </Badge>
                                            :
                                            <img
                                            src={remindernew}
                                            alt=""
                                            width={35}
                                          />
                                    }
                                </IconButton>
                            }
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                                className="headerIcon"
                                disableRipple={true}
                            >
                                <NameCircle style={{ fontSize: '13px', marginRight: '0' }} size="28px" name={splitBySpaceAndGetFirstTwoLetters(fullName)} />
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </div>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </>
    )
}

export default Header;





